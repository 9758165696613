import React from "react"

import { Link, useStaticQuery, graphql } from "gatsby"
import blogStyles from "./blog.module.scss"

import Layout from "../components/layout"
import Head from "../components/head"

const BLOG_PAGE = () => {
  const POSTS = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            date(formatString: "MMMM Do, YYYY")
            slug
          }
        }
      }
    }
  `)

  return (
    <div>
      <Layout>
        <Head title="Blog" />
        <h1>My Blog</h1>
        <p>
          These are just ordered by date right now, hoping to clean it up soon
          and have it grouped by specific interests
        </p>

        <ol className={blogStyles.posts}>
          {POSTS.allContentfulBlogPost.edges.map(edge => {
            return (
              <li className={blogStyles.post}>
                <h2>
                  <Link to={`/blog/${edge.node.slug}`}>{edge.node.title}</Link>
                </h2>
                <p className={blogStyles.date}>{edge.node.date}</p>
              </li>
            )
          })}
        </ol>
      </Layout>
    </div>
  )
}

export default BLOG_PAGE
